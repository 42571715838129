<template>
  <v-container  fluid style="margin-top:60px;margin-bottom: 67px;">
      <v-container>
      
        <v-img src="img/sup_top.png" style="margin-bottom: 15px;" max-width="100%" contain center  ></v-img>  

    

<span style="
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 21px;
line-height: 20px;

display: flex;
flex-direction: column;
align-items: center;
text-align: center;

color: #5D4F46;
margin-bottom: 20px;

">
  Технічна підтримка:
<br>
<span style="
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 20px;
/* or 71% */
display: flex;
align-items: center;
text-align: center;

color: #F07300;
margin-top: 10px;margin-bottom: -7px;;

"><a href="tel:0800330938" style="color: #F07300">0-800-330-938</a></span>
<br>
з 10:00 до 17:00 з ПН по ПТ

</span>


     
<v-img src="img/sup_b1.png" style="margin-bottom: 15px;" max-width="380px" contain center @click.native="faq=true" ></v-img>   

<v-img src="img/sup_b2.png" style="margin-bottom: 15px;" max-width="380px" contain center @click.native="write=true"></v-img>  

<v-img src="img/sup_b3.png" style="margin-bottom: 15px;" max-width="380px" contain center   @click.native="write_tp=true"></v-img>  

<v-img src="img/sup_b4.png" style="margin-bottom: 15px;" max-width="380px" contain center onclick="window.open('tel:0800330938', '_self')" ></v-img>  
<p style="text-align: center;font-size: 14px;" onclick="window.location.replace(window.location.pathname + '?cacheBust=' + new Date().getTime())">Версія додатку: {{ appVersion }}</p>



      </v-container>

 
      <v-dialog v-model="faq">
      <v-card class="simple_card">
        <v-card-title   style="z-index:1;margin-bottom:20px;justify-content:center;text-align:center;">
          <!-- header -->
          {{$t('h_faq')}}  
          <div @click="faq = false" class="close_dialog"></div>
        </v-card-title>
       
        <v-card-text>
          <v-expansion-panels
        :accordion="accordion"
        :popout="popout"
        :inset="inset"
        :multiple="multiple"
        :focusable="focusable"
        :disabled="disabled"
        :readonly="readonly"
        :flat="flat"
        :hover="hover"
        :tile="tile"
      >
        <v-expansion-panel
          v-for="(faq,i) in faqs"
          :key="i"
        >
          <v-expansion-panel-header><b>{{faq.header}}</b></v-expansion-panel-header>
          <v-expansion-panel-content v-html="faq.text" style="padding:15px;">
            
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="faq = false">   {{$t('close')}} </span>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="write">
      <v-card  class="simple_card">
        <v-card-title class="headline lighten-2" style="margin-bottom:25px;justify-content:center;text-align: center;">
          <!-- header -->
          {{$t('h_write')}}  
          <div @click="write = false" class="close_dialog"></div>
        </v-card-title>
       
        <v-card-text>
          <v-row align="top" justify="center">
         <v-col cols="10" sm="4" md="4">
             <v-textarea
            outlined
            name="input-7-4"
            label=""
            value="Питання"
            v-model=req_tp
          ></v-textarea>

          <v-btn primary large block type="submit" @click="save_request"  class="btn_new">Надіслати</v-btn>
         </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="write = false">   {{$t('close')}} </span>
        </v-card-actions> -->
       
      </v-card>
      <div class="popup_bottom_robot"></div>
    </v-dialog>

    <v-dialog v-model="write_tp">
      <v-card class="simple_card">
        <v-card-title   style="z-index:1;margin-bottom:20px;justify-content:center;text-align:center;">
          <!-- header -->
          {{$t('h_contact')}}  
          <div @click="write_tp = false" class="close_dialog"></div>
        </v-card-title>
       
        <v-card-text>
          <v-row align="top" justify="center">
         <v-col cols="10" sm="4" md="4">
             <v-textarea
            outlined
            name="input-7-4"
            label=""
            value="Питання"
            v-model=req_tp_tp
          ></v-textarea>

          <v-btn primary large block type="submit" @click="save_request_tp"  class="btn_new">Надіслати</v-btn>
         </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="write_tp = false">   {{$t('close')}} </span>
        </v-card-actions> -->
      </v-card>
      <div class="popup_bottom_robot"></div>
    </v-dialog>
    </v-container>
</template>

<script>
import btnrnd from "../components/btnrnd.vue";
import appVersion from '../main.js'; // Adjust the path if needed

// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { API_LOCATION } from "../../config";
export default {
  name: "Info",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    btnrnd,
  },
  data: () => ({
    faq:false,
    write:false,
    write_tp:false,
    contact:false,
    
    
        accordion: false,
    popout: false,
    inset: false,
    multiple: false,
    disabled: false,
    readonly: false,
    focusable: false,
    flat: false,
    hover: false,
    tile: false, 
    req_tp:'',
    req_tp_tp:'',
        apiurl:API_LOCATION,
    usage:null,
    quota:null,
    percent:null,
    appVersion,
  }),
  computed: {
    task1: function () {
      return this.cards.filter((i) => i.type == "1");
    },
    task2: function () {
      return this.cards.filter((i) => i.type == "2");
    },
    task3: function () {
      return this.cards.filter((i) => i.type == "3");
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    page_title: function () {
      return this.$store.getters.page_title;
    },
    fname: function () {
      return this.$store.getters.fname;
    },
    lname: function () {
      return this.$store.getters.lname;
    },
    points: function () {
      return this.$store.getters.points;
    },
    avatar: function () {
      return this.$store.getters.avatar;
    },
      title() {
        return this.$t('title')
      },
      faqs(){
        
        return[
          
          {'header':this.$t('q1'),'text':this.$t('a1')},
          {'header':this.$t('q2'),'text':this.$t('a2')},
          {'header':this.$t('q3'),'text':this.$t('a3')},
          {'header':this.$t('q4'),'text':this.$t('a4')},
          {'header':this.$t('q5'),'text':this.$t('a5')},
          {'header':this.$t('q6'),'text':this.$t('a6')},
          {'header':this.$t('q7'),'text':this.$t('a7')},
          {'header':this.$t('q8'),'text':this.$t('a8')},
          {'header':this.$t('q9'),'text':this.$t('a9')},
          {'header':this.$t('q10'),'text':this.$t('a10')},          
          {'header':this.$t('q11'),'text':this.$t('a11')},
          {'header':this.$t('q12'),'text':this.$t('a12')}
        ]},
  },
  watch:{
      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      }
  },
  mounted() {
    navigator.storage.estimate().then((estimate)=> {

          this.usage = estimate.usage;
            this.quota = estimate.quota;
            this.percent = ( estimate.usage / estimate.quota).toFixed(2);
          console.log("Usage:"+
               ( estimate.usage / estimate.quota).toFixed(2));
        });
     
  },
  created() {
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");
    
    
  },
  methods:{
        save_request: function(){

            axios.post(this.apiurl,  { data: 'component_data',action: 'tpq_create', 'question':this.req_tp },{ headers: { 'content-type': 'application/x-www-form-urlencoded' } })
         .then(resp =>{
           
         if(resp.data=='OK'){ 
            //this.$store.commit('setSnack', 'Запит надіслано!')
            alert('Запит надіслано!');
            this.write=false;
            this.req_tp='';
            //this.$router.push({ name: 'Profile'})
          }
          })
            
        },

        save_request_tp: function(){

            axios.post(this.apiurl,  { data: 'component_data',action: 'tpq_create_tp', 'question':this.req_tp_tp },{ headers: { 'content-type': 'application/x-www-form-urlencoded' } })
         .then(resp =>{
           
         if(resp.data=='OK'){ 
            //this.$store.commit('setSnack', 'Запит надіслано!')
            alert('Запит надіслано!');
            this.write_tp=false;
            this.req_tp_tp='';
            //this.$router.push({ name: 'Profile'})
          }
          })
            
        },

    }
};
</script>


<i18n>
{
  
  "ua":{
      "title":"Технічна підтримка",
      "btn1":"Надіслати повідомлення <br>до служби підтримки",
      "btn2":"Відповіді на часті питання",
      "btn3":"Надіслати повідомлення <br>торговому представнику",
      "close":"Закрити",
      "h_write":"Надіслати до служби підтримки",
      "h_contact":"Надіслати торговому представнику",
      "h_faq":"Відповіді на часті питання",
      "q1":"Чи можу я змінити номер телефону для участі у програмі?",
      "a1":"Так, це можливо. Зверніться, будь ласка, до Вашого Торгового Представника.",

      "q2":"Чи зберігаються мої накопичені бали?",
      "a2":"Зароблені бали мають термін дії 6 місяців від початку наступного місяця після зарахування. Встигніть використати їх до терміну згорання!",
      
      "q3":"Мені не нарахували бали за завдання «Фото вітрини»/ «Фото блоків», у чому причина?",
      "a3":"Усі фото перевіряються вручну модератором, тому модерація може займати декілька днів. Перевірте вкладку сповіщення, можливо вам прийшло повідомлення чому завдання було відправлено на повторне виконання/ відхиллено. Точну інформацію Ви можете дізнатись у свого Торгового Представника. ",

      "q4":"Які критерії враховуються при перевірці завдання «Фото вітрини»?",
      "a4":"Критеріями перевірки є  наявність усіх SKU (відповідно до обраного асортименту) та правильність їх викладки.",

      "q5":"Якщо я беру участь в програмі як мережева торгова точка, скільки SKU має бути на фото вітрини?",
      "a5":"На фото вітрини має бути 40 SKU із актуального асортименту програми (актуальний асортимент уточнюйте у Вашого Торгового Представника).",

      "q6":"Які критерії враховуються при перевірці завдання «Мій товарний запас»?",
      "a6":"Критерієм перевірки є наявність кожного SKU із списку, наведеного у завданні.",

      "q7":"Я ввів(-ла) неправильний номер картки Fishka, чи можна його змінити?",
      "a7":"Так, змінити номер карти Fishka можна. Для цього достатньо звернутися у службу тех. підтримки iTeam.",

      "q8":"Як довго чекати на замовлені сертифікати?",
      "a8":"Поповнення мобільного рахунку та нарахування балів Fishka відбувається одразу (15-20 хвилин) автоматично, замовлені електронні сертифікати «Сільпо», «Єва» та «Розетка» з'являються у застосунку одразу.",

      "q9":"Як довго діє СМС-код, який я отримав(-ла) для замовлення сертифікату?",
      "a9":"СМС-код для підтвердження замовлення діє протягом 20 хвилин. Протягом цього часу ви маєте змогу замовити декілька сертифікатів без повторного смс-підтвердження.",

"q10":"Для замовлення сертифікатів потрібно декілька СМС-підтверджень?",
"a10":"СМС-код для підтвердження замовлення діє протягом 20 хвилин. Протягом цього часу ви маєте змогу замовити декілька сертифікатів без повторного смс-підтвердження. ",

"q11":"Чи можу я дізнатися поточний стан бонусів та перевірити надходження?",
"a11":"Звісно! Для цього відкрийте розділ «Бали» та оберіть період, який вас цікавить. Ви зможете дізнатися повну інформацію про надходження та списання балів. ",

"q12":"Де я можу знайти інформацію про бренди? ",
"a12":"Інформацію про асортимент ІТЮ ви можете дізнатися перейшовши у вкладку відповідного бренду на головній сторінці. Також слідкуйте за розділом Новини, де ми публікуємо актуальні новини не тільки про портфель брендів ІТЮ, а й новини тютюнової галузі. "



      

  }
}
</i18n>